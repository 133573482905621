import React from 'react';
import problemImage from '../img/problema.png';
import './QuestionsSection.css';  // Import your custom CSS
import decorationImage from '../img/decoration-3.png';

const QuestionsSection = () => {
  return (
    <section id="problem" className="questions-section slide">
      <div className="slide-background">
        <img src={decorationImage} className='contain right-force' alt="Decoration" />
      </div>
      <div className="container py-0 p-5 text-center">
        <div className="col text-center pt-5 order-1 order-md-2">
          <h3 className="display-5 fw-bold mb-4">Você já se perguntou?</h3>
          <div className="row text-center">
            <div className='col'>
              <div className="box1 sb7 p-3 h5 d-block " style={{ float: 'right' }}>
                Quem ganha com a administração do seu saldo em contas e fundo de reserva?
              </div>
            </div>
            <div className='col'>
              <div className="box1 sb7 p-3 h5" style={{ float: 'left' }}>
                Porque precisa aguardar prazos excessivamente longos para ter demandas simples e urgentes respondidas?
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className='col'>
              <div className="box1 sb7 p-3 h5 mt-4 d-block" style={{ float: 'right' }}>
              Como identificar quais tarefas priorizar para ir além da rotina e elevar a qualidade do seu condomínio?
              </div>
            </div>
            <div className='col'>
              <div className="box1 sb7 p-3 h5 mt-4" style={{ float: 'left' }}>
              Quanto tempo sobraria para você se dedicar à gestão com um suporte proativo e completo?
              </div>
            </div>
          </div>
          <p className='h4 mt-5'>Fizemos essas e muitas perguntas e acreditamos que é hora de romper o status quo e evoluir a administração condominial a um novo patamar, 100% pró cliente.</p>
          <p className='h4 fw-bold my-5'>Não é porque sempre foi assim que precisa continuar a ser.</p>
        </div>
      </div>
    </section>
  );
};

export default QuestionsSection;
