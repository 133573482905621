import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import QuestionsSection from './components/QuestionsSection';
import ValuesSection from './components/ValuesSection';
import CalculatorSection from './components/CalculatorSection';
import SolutionSection from './components/SolutionSection';
import Footer from './components/Footer';
import ModalForm from './components/ModalForm';
import LoginForm from './components/LoginForm';

function App() {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  
  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleLoginShow = () => setShowLoginModal(true);
  const handleLoginClose = () => setShowLoginModal(false);

  return (
    <div className="App">
      <Header handleShow={handleShow} handleLoginShow={handleLoginShow} />
      <HeroSection handleShow={handleShow} handleLoginShow={handleLoginShow} />
      <QuestionsSection />
      {/* <ValuesSection /> */}
      <SolutionSection />
      <CalculatorSection />
      <Footer handleShow={handleShow} />
      <ModalForm show={showModal} handleClose={handleClose} />
      <LoginForm show={showLoginModal} handleClose={handleLoginClose} />
    </div>
  );
}

export default App;
