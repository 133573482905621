import React from 'react';
import solutionImage from '../img/solucao.png';
import solutionBackImage from '../img/solutionBack.png';
import icon1 from '../img/icon1.png';
import icon2 from '../img/icon2.png';
import icon3 from '../img/icon3.png';
import icon4 from '../img/icon4.png';
import icon5 from '../img/icon5.png';
import decorationImage from '../img/decoration-4.png';
import './SolutionSection.css';

const SolutionSection = () => {
  return (
    <section id="solution" className="solution-section text-start slide">
      <div className="slide-background bg-light">
        <img src={decorationImage} className='contain left bottom' alt="Decoration" />
      </div>
      <div className="container px-5">
        <div className="row align-items-start desktop-only">
          <div className="col-lg-7 offset-lg-1 order-sm-1 order-lg-2">
            <div className="row d-block d-lg-none mb-5">
              <div className="col">
                <h3 className="display-5 fw-bold mt-5">Quem somos</h3>
                <p className='h4'>Somos uma startup de Administração de Condomínios formada por um time de excelência em gestão corporativa e condominial.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-3">
                <div className="card no-border">
                  <div className="card-body p-3 pb-2">
                    <div>
                      <img src={icon1} alt="Icon 1" className="icon img-fluid float-start me-3 mb-3" />
                      <h4>Isento de conflito de interesse</h4>
                    </div>
                    <div className="float-start">
                      <p className='h'>Nosso foco é exclusivamente no melhor interesse do condomínio. Não cobramos "pedágios", a ética é nosso principal pilar.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mb-3">
                <div className="card no-border">
                  <div className="card-body p-3 pb-2">
                    <div>
                      <img src={icon2} alt="Icon 2" className="icon img-fluid float-start me-3 mb-3" />
                      <h4>Tecnologia a seu favor</h4>
                    </div>
                    <div className="float-start">
                      <p className='h'>Aprimoramos processos e não aumentamos a carga de trabalho do síndico.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mb-3">
                <div className="card no-border">
                  <div className="card-body p-3 pb-2">
                    <div>
                      <img src={icon3} alt="Icon 3" className="icon img-fluid float-start me-3 mb-3" />
                      <h4>Sem conta pool</h4>
                    </div>
                    <div className="float-start">
                      <p className='h'>O condomínio se beneficia diretamente da gestão financeira de seus ativos.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mb-3">
                <div className="card no-border">
                  <div className="card-body p-3 pb-2">
                    <div>
                      <img src={icon4} alt="Icon 4" className="icon img-fluid float-start me-3 mb-3" />
                      <h4>Gestão ética e moderna</h4>
                    </div>
                    <div className="float-start">
                      <p className='h'>Aplicamos práticas modernas de gestão e compliance ao dia a dia da administração condominial.</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 col-sm-12 mb-3">
                <div className="card no-border">
                  <div className="card-body p-3 pb-2">
                    <div>
                      <img src={icon5} alt="Icon 5" className="icon img-fluid float-start me-3 mb-3" />
                      <h4>Práticas modernas de gestão</h4>
                    </div>
                    <div className="float-start">
                      <p className='h'>Nosso time traz práticas corporativas modernas aplicadas à gestão condominial.</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="col-lg-4 order-sm-2 order-lg-1">
            <div className='d-none d-lg-block'>
              <h3 className="display-5 fw-bold ">Quem somos</h3>
              <p className='h4'>Somos uma startup de Administração de Condomínios formada por um time de excelência em gestão corporativa e condominial.</p>
            </div>
            <div className='text-center'>
              {/* <img src={solutionImage} alt="Problem Example" className="img-fluid" style={{ maxHeight: '550px' }} /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionSection;
