import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';

const ModalForm = ({ show, handleClose }) => {
    const [formData, setFormData] = useState({
        nomeCompleto: '',
        email: '',
        cidade: '',
        whatsapp: '',
        empresa: '',
        cargo: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (show) {
            setSubmitted(false);
            setIsLoading(false);
        }
    }, [show]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formUrl = 'https://script.google.com/macros/s/AKfycbwacKDl7umiPIQOr9dxqauwElsA2n11nnoRbLm0yx26uFKNJJtOFRuprPcVfzOOPO2F/exec';

        fetch(formUrl, {
            method: 'POST',
            body: JSON.stringify(formData),
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(() => {
                setSubmitted(true);
                setIsLoading(false);
                setTimeout(handleClose, 2000);
            })
            .catch((error) => {
                alert('Error submitting form', error);
                setIsLoading(false);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Lista de Espera para Demo Exclusiva</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!submitted ? (
                    <div>
                        <p>Inscreva-se para ser um dos primeiros a experimentar nossa solução inovadora!</p>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formNomeCompleto">
                                <Form.Label>Nome Completo</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Digite seu nome completo"
                                    name="nomeCompleto"
                                    value={formData.nomeCompleto}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formEmail" className="mt-3">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Digite seu e-mail"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formCidade" className="mt-3">
                                <Form.Label>Cidade</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Digite sua cidade"
                                    name="cidade"
                                    value={formData.cidade}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formWhatsApp" className="mt-3">
                                <Form.Label>WhatsApp</Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder="Digite seu WhatsApp"
                                    name="whatsapp"
                                    value={formData.whatsapp}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="btn mt-4" disabled={isLoading}>
                                {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Enviar'}
                            </Button>
                        </Form>
                    </div>
                ) : (
                    <div>
                        <h2>Obrigado, suas informações foram enviadas!</h2>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ModalForm;
